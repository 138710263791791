/*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
*/

a {color:#f4b54e;}
a:hover {color:#a65628;}

/*.spacer.s0 { min-height: 30px; }*/

/*html {
  height: 100%;
}*/

/*body {
    position: absolute;

    width: 100%;
    height: 100%;

    -webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;

    text-align: center;
  }*/

/*.section {
	text-align:left;
	padding:20px;
	max-width: 800px;
	margin: 0 auto;
}

*, *:before, *:after {
  box-sizing: border-box;
}
*/
/* Dialogues */

/*.button-holder {
  margin:20px;
}*/

.btn {
	display: inline-block;
	text-align: center;
	text-decoration: none;
	font-size:130%;


	margin: 2px 0;

	border: solid 2px white;
	border-radius: 10px;


	padding: 0.5em 1em;


	color: #fff;
	background: none;

	cursor:pointer;

	width:auto;
}